




import { Component, Mixins } from 'vue-property-decorator'

import MGbyCourseMixin from '@/mixins/master/GetMGbyCourseMixin'

@Component
export default class GoToMasterExercise extends Mixins(MGbyCourseMixin) {
  protected get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private mounted () {
    this.getMasterGroup()
      .then(() => {
        this.$router.replace({
          name: 'master.exercises.item',
          params: {
            exerciseUUID: this.exerciseUUID,
            groupID: this.currentMasterGroupID.toString(),
          },
        }).catch(() => {return})
      })
  }
}
